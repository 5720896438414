import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { getUrlOfEndpoint } from '@util/functions/strings';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KurzFileDownloadService {

  constructor(private readonly httpClient: HttpClient) { }

  downloadFile(endpoint: string, params: Params, mimeType: string, fileName: string): Observable<any> {
    const url = getUrlOfEndpoint(endpoint, params);
    return this.httpClient.get(url, { responseType: 'arraybuffer' }).pipe(tap(res => {

      const blob = new Blob([res], { type: mimeType });
      const linkUrl = URL.createObjectURL(blob);
      const a = document?.createElement('a');

      if (a) {
        a.download = fileName;
        a.href = linkUrl;
        a.click();
      }

    }));
  }
}

export class KurzFileDownloadTestingService {
  downloadFile(endpoint: string, params: Params, mimeType: string, fileName: string): Observable<any> {
    return EMPTY;
  }
}
